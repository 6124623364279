body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
@apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}


/* styles.css */
.date-time-picker-wrapper {
  position: relative;
}

.date-time-picker-wrapper .MuiDateTimePicker-root .MuiCalendarPicker-root {
  top: -20rem; /* Adjust based on your design */
  transform: translateY(-10px); /* Fine-tune the positioning */
  position: absolute !important;
}

